















































import { Component } from "vue-property-decorator";

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import { MyCertificationsStoreDefault } from "./store";
import { MyCertificationViewModel } from "@/services/iResourceService";
import PgaTypeCertification from "@/feature/Resource/Certifications/Components/PgaTypeCertification.vue";
import PgaCertificationDatePicker from "@/feature/Resource/Certifications/Components/PgaCertificationDatePicker.vue";

@Component( {
	components:
	{
		PgaTypeCertification,
		PgaCertificationDatePicker,
	},
} )
export default class MyCertificationsIndex extends mixins( SharedMixin ) {
	private isLoading = true;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private headers: Array<any> =
		[
			{
				text: 'Code',
				align: 'start',
				sortable: true,
				value: 'Code',
				keySearch: "co",
			},
			{
				text: 'Description',
				allign: 'start',
				value: 'Description',
				keySearch: "de",
			},
			{
				text: 'Vendor',
				allign: 'start',
				value: 'Vendor.BusinessName',
				keySearch: "ve",
			},
			{
				text: 'Active',
				allign: 'start',
				value: 'IsActive',
			},
			{
				text: 'Type',
				allign: 'start',
				value: 'Type',
			},
			{
				text: 'Achievement Date',
				align: 'start',
				sortable: true,
				value: 'AttainmentDate',
				width: "25%",
			},
			{
				text: "Expire Date",
				align: "start",
				sortable: false,
				value: "ExpireDate",
			},
		];

	get certificationsList (): Array<MyCertificationViewModel>
	{
		return MyCertificationsStoreDefault.MyCertifications.Certifications;
	}

	selectCertifications (): void
	{
		this.$router.push( { name: 'mycertificationsselect' } );
	}

	async mounted (): Promise<void>
	{
		try
		{
			this.isLoading = true;
			await MyCertificationsStoreDefault.GetMyCertifications();
		} finally
		{
			this.isLoading = false;
		}
	}
}
